.testcardr {
   background: radial-gradient(circle, rgba(0, 0, 0, 0.3563550420168067) 0%, rgba(0, 0, 0, 1) 100%);
   border-radius: 21px 21px 10px 10px;
   margin: 15px;
   background-color: rgba(5, 0, 141, 0.742);

}

.testcardr:hover {
   background-color: blue;
   opacity: 0.9, 0.9, 0.9;
   box-shadow: '4px 4px 20px black';
}

@media (max-width: 767px) {


   #card {
      background-color: rgba(11, 5, 193, 0.89) ;
    }

   .cardImg {
      max-width: 275px;
      max-height: 230px;
   }


}

.cardImg {
   width: 500px;
   height: 300px;
   border-radius: 21px 21px 10px 10px;
   margin-bottom: 100px;
   margin-left: 2%
}